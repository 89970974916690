@import url('https://fonts.googleapis.com/css2?family=Jacques+Francois&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'Jacques Francois', serif;
}



.banner-bg {
    background: url(../../Assets/img/banner-logo.png) no-repeat center center/cover;
    position: relative;
    height: 100vh;
    opacity: .9;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.ff {
    font-family: "'Jacques Francois', serif" !important;
}


.banner-bg .circle {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    right: 30vw;
    top: 30vh;
    animation: pop 1.5s infinite;
}


.banner-bg .circle1 {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 17vw;
    bottom: 24vh;
    animation: pop 1.5s infinite;
}

.banner-bg .circle2 {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 33vw;
    bottom: 38vh;
    animation: pop 1.5s infinite;
}

.banner-bg .circle3 {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    right: 19vw;
    bottom: 30vh;
    animation: pop 1s infinite;
}

.banner-bg::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    position: absolute;
    z-index: -99;
    opacity: 0.6;
}

.banner-text {
    color: white;
    display: flex;
    flex-direction: column;
    left: 0;
    float: left;
    margin-left: 8rem;
}

.icons-wrapper {
    width: 100vh;
}

.foot {
    display: grid;
    place-items: center;
    line-height: 33px;
    font-weight: 400;
    font-size: 25px;
}

.btn5 {
    background-color: transparent;
    padding: 10px 30px 10px 30px;
    margin-bottom: 40px;
    border: 1px white solid;
    z-index: 99;
    cursor: pointer;
}

.btn5:hover {
    transition: var(--button-transition);
    background-color: white;
    color: var(--brown-background) !important;
}

.icons {
    gap: 30px;
}


/* Moblie Screens  */
@media (max-width:500px) {
    .text_head {
        margin-top: 50px;
        width: 90%;
        height: 300px;
    }

    .btn5 {
        padding: 2px 20px !important;
    }

    .iconAndTextWrapper {
        width: 70px;
    }

    .ff {
        width: 100% !important;
        text-align: center !important;
        padding-top: 10px !important;
    }

    .icon--container {
        width: 100% !important;
        display: contents;
    }

    .banner-text {
        display: flex;
        flex-direction: column;
        left: 0;
        float: left;
        margin-left: 2rem;
    }


    .foot {
        display: grid;
        margin-top: 30px !important;
        place-items: center;
        font-size: 10px;
    }

    .foot h4 {
        font-size: 15px !important;
    }

    .banner-bg::after {
        height: 100%;
    }

    .banner-bg {
        height: 100%;
        padding: 20px !important;
    }

    .foot h4 {
        font-size: 15px !important;
    }

    .text_head {
        margin-top: 10px;
    }

    .icons-wrapper p{
        font-size:0.7rem !important;
    }
}


@media (min-width:481px) and (max-width:768px) {
    .banner-bg {
        padding: 2rem !important;
    }

    .text_head {
        height: 15rem !important;
    }

    .btn5 {
        padding: 0.3rem 1rem !important;
    }
}

/* tablet and laptop screen */
@media (min-width:769px) and (max-width:1024px) {
    .text_head {
        width: 25rem !important;
    }

    .banner-bg {
        height: 100vh;
    }

    .foot {
        margin-top: 50px;
    }

    .text_head {
        margin-top: 0px !important;
        margin-left: 0 !important;
    }

    .foot h4 {
        font-size: 10px !important;
        width: 70%;
    }

    .btn5 {
        padding: 5px 30px !important;
        font-size: 18px;
    }

    .banner-bg::after {
        height: 100%;
    }
}

/* laptops */
@media (min-width: 769px) and (max-width: 1024px) {
    .foot h4 {
        font-size: 20px !important;
        width: 80% !important;
    }

    .text_head {
        height: 30vh;
    }

    .banner-bg .circle {
        top: 33vh !important;
        right: 24vw !important;
    }

    .banner-bg .circle1 {
        bottom: 33vh !important;
        left: 14vw !important;
    }

    .banner-bg .circle2 {
        bottom: 40vh !important;
        left: 27vw !important;
    }

    .banner-bg .circle3 {
        bottom: 32vh !important;
        right: 10vw !important;
    }
}

/* large screens */
@media (min-width:1025px) and (max-width:2559px) {
    .text_head {
        width: 30rem;
        height: 450px;
        margin-top: -35px;
    }

    .foot h4 {
        font-size: 20px;
    }

}

/* 4k Screens */
@media (min-width:2560px) {
    .text_head {
        /* background: red; */
        width: 70rem;
    }

    .foot h4 {
        font-size: 3rem !important;
        width: 80%;
    }

}

@media (min-width:1441px) {
    .text_head {
        width: 50rem;
    }

    .foot h4 {
        font-size: 2rem;
    }
}


/* Small Laptop Screen */

@media(min-width:1020px) and (max-width:1500px) {
    .text_head {
        height: 20rem;
    }

    .icon--img {
        width: 3rem !important;
        height: 3rem !important;
    }

    .btn5 {
        font-size: 0.7rem;
    }

}

/* Mobile to tablet */
@media (max-width: 1020px) {
    .icons-wrapper {
        width: 75% !important;
    }

    .icon--img {
        height: 50px;
        width: 50px;
        line-height: 0%;
    }


    .ff {
        font-size: 14px;
        margin-top: 20px;
        line-height: 1;
    }

    .banner-bg .circle,
    .banner-bg .circle1,
    .banner-bg .circle2,
    .banner-bg .circle3 {
        display: none !important;
    }
}


@media (max-width:765px) {
    .icons-wrapper {
        display: flex !important;
        justify-content: center !important;
    }

    .icon--img {
        height: 40px;
        width: 40px;
    }

    .ff {
        font-size: 10px;
        width: 50%;
    }

    .banner-bg .circle,
    .banner-bg .circle1,
    .banner-bg .circle2,
    .banner-bg .circle3 {
        display: none !important;
    }

}

@media (min-width:1000px) {
    .icons-wrapper {
        width: 50% !important;
    }
}

@media (min-width:501px) and (max-width:770px){
    .text_head{
        height:10rem !important;
    }
    .btn5{
        font-size:0.6rem !important;
    }
    .icons-wrapper{
        width: 70vw !important;
    }
    .icons-wrapper_hero_page{
        display:flex !important;
        gap:3rem;
    }
    .header_container{
        margin: 0px !important;
        margin-bottom: 20rem !important;
    }
}