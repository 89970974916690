* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.Our_Product_Container {
    /* background: #462828; */
    /* position: relative; */
    height: 100vh;
}

.banner {
    background: url(../../Assets/img/ourProduct-img.png) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    z-index: 1;
    position: absolute;
}

.banner::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    position: absolute;
    z-index: -10;
    opacity: 0.6;
}

.our-product {
    height: 40%;
    object-fit: contain;
}

@media (max-width:480px) {
    .our-product {
        height: 20vh !important;
        width: 80vw !important;
        padding: 0px 10px !important;
    }

    .op-container {
        height: fit-content !important;
    }

    .banner {
        height: 100%;
    }
}

@media (min-width:480px) and (max-width:1160px) {
    .our-product {
        height: 25% !important;
    }
}

@media (min-width:500px) and (max-width:720px){
    .our-product{
        height: 16% !important;
    }
}

@media (min-width:481px) and (max-width:680px){
    .our-product{
        height: 10rem !important;
    }
}