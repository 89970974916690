* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    margin-bottom: auto !important;
}

.shopping-cart-page-container {
    height: 100vh;
}

.shopping_cart_header_text {
    padding: 5rem 13rem;
    padding-bottom: 2rem !important;
}

.shop-cart-heading {
    gap: 10rem;
}

.product-ID {
    display: flex;
    flex-direction: column;
    gap: 2rem !important;
    justify-content: space-between !important;
}

p {
    font-size: 1.1rem;
}

.button_wrapper {
    position: relative;
    right: 10rem;
}

.product-ID>code {
    font-size: 0.8rem !important;
    color: #AB6C6C !important;
}

.btn {
    color: white;
}

.btn:hover {
    color: white !important;
}


.products-id-heads {
    position: relative;
    left: 9rem !important;
}

.each_product {
    background-color: #523030;
}

/* Shopping cart page */
.btn_in_shopping_cart {
    background-color: transparent !important;
    border: 1px solid white !important;
}

.btn_in_shopping_cart:hover {
    background-color: white !important;
    color: var(--brown-background) !important;
    transition: var(--button-transition);
    box-shadow: none !important;
}

.btn_in_shopping_cart:active,
.btn_in_shopping_cart:disabled {
    background-color: white !important;
    color: var(--brown-background) !important;
    box-shadow: none !important;

}

@media (min-width:1330px) and (max-width:1440px) {
    .total-price {
        right: 0 !important;
        padding-right: 70px;
    }
}

/* No Product Page */
.no-product-page {
    display: flex;
    padding: 6rem 0;
    justify-content: center;
}

.no-product-page h1 {
    font-size: 2rem !important;
    margin: -5rem 0rem !important
}


/* Modal page */
.modal-content {
    background-color: var(--brown-background) !important;
    padding: 2rem;
}

.form_font {
    font-size: 0.85rem !important;
}

.form-control {
    border-radius: none !important;
}

.btn-close {
    color: red !important;
}

@media (max-width:770px) {
    .button_wrapper, .total-price {
        right: 0rem !important;
    }

    .btn_in_shopping_cart {
        font-size: 1rem !important;
        padding: 0.5rem 1rem !important;
    }

    .shopping_cart_header_text {
        width: 22rem !important;
        padding-left: 2rem !important;
    }

    .shopping-cart-page-container {
        padding: 0px !important;
        padding-left: 0.6rem !important;
    }

    .shopping-cart-page-container p, p {
        font-size: 1.1rem !important;
    }

    .products-id-heads {
        left: 0rem !important;
    }
}

@media (min-width:771px) and (max-width:1050px) {
    .shopping_cart_header_text {
        padding-left: 5rem !important;
        width: 60rem !important
    }

    .place_order_text {
        width: 20rem;
        height: 3rem;
    }
}

@media (max-width:767px) {
    .modal-content {
        padding: 2rem !important;
    }

    .modal-header .btn-close {
        display: none;
    }

    .formHead {
        padding: 0rem 1rem !important;
    }

    .form-control {
        margin: 0.3rem 0rem !important;
    }
}

@media (max-width:440px) {
    .place_order_text {
        width: 12rem;
        height: 2rem;
    }
}

@media (max-width:700px) {
    .heading_for_products {
        visibility: hidden !important;
    }

    .each_product {
        margin: 0rem !important;
    }

    .each--product {
        padding: 0rem 3rem !important;
        margin: 0rem 1rem !important;
        width: 100% !important;
    }

    .part2 {
        display: flex !important;
        padding-left: 3rem !important;
        flex-direction: column;
    }

    .part3 {
        display: flex !important;
        flex-direction: column !important;
    }

    .product-ID {
        margin: 0rem 0.7rem !important;
    }

    .shopping-cart-page-container {
        padding: 0rem 0.5rem !important;
    }
}