@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');

:root {
  /*colors*/
  --dark-red: #670103;
  --brown-background: #462828;
  --dark-black: #202020;
  --dark-blue: #005353;
}

.App {
  background-color: var(--brown-background);
  height: 100%;
}



h1, h2, h3, h4, h5, p {
  color: white !important;
  font-family: 'Inter', sans-serif;
  font-weight: lighter !important;
}

.h4 {
  font-size: 0.5px !important;
}


/* hero section */
.hero-wrapper {
  padding: 5rem 6rem !important;
}

.hero_wrapper {
  display: flex !important;
}

.black {
  background-color: var(--dark-black);
  padding: 25px;
  border-radius: 50%;
}

.blue, .red, .black {
  cursor: pointer !important;
}

.blue {
  background-color: var(--dark-blue);
  padding: 25px;
  border-radius: 50%;
}

.red {
  background-color: var(--dark-red);
  padding: 25px;
  border-radius: 50%;
}



@media screen and (max-width:500px) {

  * {
    margin: auto !important;
    padding: auto !important;
  }

  .all_products_page_container {
    padding: 0rem 1rem important;
  }

  .buttons-container {
    display: none !important;
    visibility: hidden !important;
  }

  .sanitary-wares-on-product-page {
    height: 7rem !important;
  }

  .all_products_page_text {
    font-size: 1rem !important;
    text-align: left !important;
    margin-bottom: 20rem !important;
  }

  .hero_container {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .toilet_on_all_products_page {
    height: 10rem !important;
    margin-bottom: 2rem !important;
  }

  .hero-wrapper {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .hero_wrapper {
    margin: 0rem 3rem !important;
  }


  .blue, .red, .black {
    padding: 0.6rem !important;
    margin-right: 0.5rem !important;
  }

  .colors_wrapper {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 2rem !important;
  }

  .color_text {
    margin: 0px !important;
  }



}

@media (min-width:501px) and (max-width:1100px) {
  .sanitary-wares-on-product-page {
    width: 20rem;
    height: 10rem;
  }

  .hero-wrapper {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .hero_container {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .hero-wrapper {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .all_products_page_container {
    padding: 0rem 1rem !important;
  }


  .toilet_wrapper {
    width: 100% !important;
  }

  .toilet_on_all_products_page {
    height: 20rem !important;
    margin-bottom: 2rem !important;
    align-self: center !important;
  }

  .all_products_page_text {
    font-size: 1.5rem !important;
    padding: 0px 80px !important;
    text-align: center !important;
    margin-bottom: 20rem !important;
  }

  .colors_wrapper {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 2rem !important;
  }
}

/* For products */
.products {
  margin: 0px 6rem;
}

.buttons-container {
  border-bottom: 1px solid white;
  margin-right: 2rem;
}

.buttons {
  text-transform: uppercase;
  border: 0.5px solid white !important;
  background: transparent;
  padding: 0rem 2rem;
  border-bottom: 0px solid white !important;
  font-size: 20px;
}

.all-products-container {
  margin-top: 4rem;
  flex-wrap: wrap;
}

.buttons-container {
  display: flex !important;
}

.mobile_dropdown_all_product {
  display: none !important;
}

.cart_icon {
  position: absolute;
  width: 2rem;
  bottom: 30px;
  right: 28px;

}

.product-card {
  position: relative;
}

.productImg {
  position: relative;
}

.all_products_btn {
  padding: 0.2rem 7rem !important;
  background-color: transparent;
  color: white;
  border: 0.6px solid white;
}

.all_products_btn:hover {
  background-color: white;
  color: var(--brown-background) !important;
  transition: var(--button-transition);
}


@media (max-width:500px) {
  .all-products-container {
    display: flex !important;
    justify-content: center !important;
  }


  .buttons-container {
    display: none;
  }

  .btn-primary{
    background: transparent !important;
    border: none !important;
  }
  .dropdown-menu.show{
    padding: 1rem !important;
  }
}

/* swiper js */
.product-images-slider .swiper-button-prev,
.product-images-slider .swiper-button-next {
  color: #ab6c6c;
}

.product-images-slider .swiper-button-prev:after,
.product-images-slider .swiper-button-next:after {
  font-size: 2rem;
}


@media (min-width:1330px) and (max-width:1440px) {
  .buttons {
    font-size: 15px !important;
    padding: 0rem 1.5rem;
  }

  .blue, .red, .black {
    padding: 18px;
  }
}

@media (min-width:1020px) and (max-width:1329px) {
  .buttons {
    font-size: 13px !important;
    padding: 0rem 1rem;
  }

  .buttons-container {
    margin-right: 0.5rem;
    margin-bottom: 2rem !important;
  }

  .colors-warapper {
    width: 100% !important;
    background-color: blue !important;
  }

  .black,
  .red,
  .blue {
    padding: 1rem !important;
  }

  .productImg {
    height: 18rem !important;
  }
}


@media (min-width:771px) and (max-width:1019px) {
  .buttons-container {
    display: none !important;

  }
}

@media (min-width:501px) and (max-width:770px) {

  * {
    margin: auto !important;
    padding: auto !important;
  }

  .hero-wrapper {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .hero_container {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .hero-wrapper {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }

  .all_products_page_container {
    padding: 0rem 1rem !important;
  }


  .toilet_on_all_products_page {
    height: 20rem !important;
    margin-bottom: 2rem !important;
  }

  .all_products_page_text {
    font-size: 1.5rem !important;
    padding: 0px 80px !important;
    text-align: center !important;
    margin-bottom: 20rem !important;
  }

  .colors_wrapper {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    margin-bottom: 2rem !important;
  }

  .buttons--container {
    gap: 20px !important;
  }

  .buttons-container {
    display: none !important;
  }

  .mobile_dropdown_all_product {
    display: flex !important;
    margin-bottom: 20px !important;
  }

  .dropdown_wrapper {
    width: fit-content !important;
    text-align: center !important;
  }
}

@media (min-width:501px) and (max-width:768px) {

  .black,
  .blue,
  .red {
    padding: 0.8rem !important;
  }
}

@media (min-width:669px) and (max-width:1019px) {

  .black,
  .blue,
  .red {
    padding: 01rem !important;
    margin-bottom: 1rem !important;
  }
}


@media (min-width:1441px) and (max-width:1680px) {
  .buttons {
    font-size: 1rem !important;
  }
}

@media (min-width:1441px) and (max-width:1500px) {

  .black,
  .blue,
  .red {
    padding: 1.3rem !important;
  }
}
