:root {
  /*colors*/
  --dark-red: #670103;
  --brown-background: #462828 !important;
  --dark-black: #202020;
  --dark-blue: #005353;
  --button-transition: 0.8s all;
}

h1 {
  font-size: 4rem !important;
}

.App {
  max-width: 100vw;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
  /* transition: var(--button-transition); */
}

@keyframes pop {
  0% {
    box-shadow: #ffffff 0 0 0 0;
  }

  75% {
    box-shadow: #ffffff00 0 0 0 30px;
  }
}

.go2072408551 {
  padding: 8px 10px !important;
  max-width: 320px !important;
}

.styles-module_whatsappButton__IWx9V {
  z-index: 99;
}
