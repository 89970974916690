* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.lux-container .circle {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 70vw;
    z-index: 99 !important;
    top: 30vh;
    animation: pop 1.5s infinite;
}

.luxuryWrapper {
    margin-top: -1% !important;
}

.gg {
    position: relative;
    z-index: 2;
}

.lux-container {
    background: url(../../Assets/img/lux-bg.png) no-repeat center center/cover;
    position: relative;
    width: 100vw;
    height: 100vh;
}

.lux-container::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    position: absolute;
    opacity: 0.8;
}


.lux {
    position: absolute;
    z-index: 1;
    height: 30%;
}

.lux-chair {
    width: 20rem;
    border-radius: 3%;
}

.sign {
    height: 24rem;
}

.bottom-text {
    display: grid;
    place-items: center;
    text-align: center;
}

.bottom-text p {
    width: 27%;
    color: white;
    font-family: 'Jacques Francois', serif;
    padding-bottom: 40px;
    padding-top: 100px;
}

.btn4 {
    background: transparent;
    color: white;
    padding: 10px 40px 10px 40px;
    border: 1px solid white;
}

.btn4:hover {
    background-color: white;
    color: var(--brown-background);
    transition: var(--button-transition);
}

@media (max-width:480px) {
    .sign {
        display: none;
    }

    .lux-container {
        height: 100%;
    }

    .lux-chair {
        transform: translateX(-65px);
    }

    .gg {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .circle {
        display: none !important;
    }

    .bottom-text p {
        width: 60%;
        margin-top: 2rem !important;
    }

    .btn4 {
        padding: 0.4rem 1rem !important;
        margin-top: 1rem !important;
    }
}

@media (min-width:481px) and (max-width:768px) {
    .bottom-text p {
        margin-top: 3rem !important;
        width: 60% !important;
    }

    .btn4 {
        padding: 10px 30px !important;
    }
}

@media (min-width:769px) and (max-width:1800px) {
    .lux-container {
        height: 100%;
    }
}