* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


.navbar_links:hover {
    transform: scale(1.03);
    transition: all 0.5s ease-in;
}

.full a {
    font-weight: 400;
}

.drop-down {
    display: none;
}

.cart_icon_page {
    display: none;
}

p {
    margin-bottom: 0 !important;
}

.dropdown--wrapper {
    padding: 20px !important;
}

.icons--in--dropdown {
    margin-top: 10px !important;
}

.navbar-wrapper {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    align-items: center;
    text-align: right;
}

.part1 {
    transform: translateY(50px);
}

.cart-count {
    background-color: red;
    padding: 0px 2.5px !important;
    border-radius: 5px;
    position: relative;
    top: -10px;
    left: -5px;
    color: white;
    text-decoration: none !important;
}


@media only screen and (max-width:600px) {
    .empty {
        display: none;
    }

    .full {
        display: flex;
        text-align: center;
        justify-content: center;
    }
}



/* Large Screens */
@media (min-width:2559px) {
    .motomart-logo {
        width: 12rem !important;
        height: 5rem;
    }

}

/* Mobile to tablet */
@media (max-width: 800px) {
    .links--icons {
        visibility: hidden;
        display: none !important;
    }

    .navbar--wrapper {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .navbar-logo {
        display: flex !important;
        justify-content: space-between !important;
    }

    .dropdown {
        display: flex !important;
        justify-content: center !important;
        background-color: transparent;
        width: 6rem !important;
    }

    .icons--in--dropdown {
        font-size: 1rem !important;
    }

    .cart_icon_page {
        display: flex !important;
    }


    .navbar--wrapper .motomart-logo{
        padding-left: 5rem !important;
    }

     .navbar-logo {
        padding: 0rem !important;
    }

    .arrow-off::after {
        display: none !important;
    }
    
    .drop-item {
        font-size:1rem !important;
        margin-bottom: 1rem !important;
    }

    .drop-item:hover {
        background-color: #462828 !important;
        color: white !important;
    }
}

@media screen and (min-width:801px) {
    .drop-down {
        display: none !important;
    }
}


@media(min-width:990px) and (max-width:1300px) {
    .navbar_links {
        font-size: 8px !important;
    }
}

@media (min-width:801px) and (max-width:991px) {
    .links--icons {
        display: none !important;
        visibility: hidden !important;
    }
}

@media (min-width:992px) {
    .dropdown-toggle {
        display: none !important;
        visibility: none !important;
    }
}