* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.About_Sujimoto {
    background: url(../../Assets/img/about_sm.png) no-repeat center center/cover;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: 8rem 0rem;
}

.About_Sujimoto::after {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: black;
    position: absolute;
    z-index: 2;
    opacity: 0.6;
}

.text1 {
    font-family: 'Jacques Francois', serif;
    font-size: 1rem !important;
    text-align: left;
    position: relative;
    z-index: 4;
}

.suji {
    height: 25rem;
    position: relative;
    z-index: 4;
}

.about-sm {
    width: 38rem !important;
    margin-bottom: 30px;
    position: relative;
    margin-right: 10rem !important;
    z-index: 4;
}


@media (max-width:500px) {
    .About_sm {
        padding: 1.5rem 0.8rem !important;
        height: 100%;
    }

    .about_text {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }

    .about-sm {
        width: 20rem !important;
        align-items: center !important;
        justify-content: center !important;
        margin: 2rem 0rem !important;
    }

    .text1 {
        text-align: center;
        padding: 0px 2rem !important;
    }

}

@media (min-width:481px) and (max-width:768px) {
    .About_sm {
        height: 100%;
        padding: 20px 10px !important;
    }


    .about_text p {
        margin-bottom: 20px !important;
    }

    .about-sm {
        width: 20rem !important;
        float: left;
        margin-bottom: 1rem !important;
    }

    .text1 {
        font-size: 0.9rem !important;
    }

    .suji {
        height: 17rem !important;
    }
}


@media (min-width:769px) and (max-width:1800px) {
    .About_sm {
        height: 100%;
        padding: 6rem 0rem !important;
    }

}

@media(min-width:775px) and (max-width:1279px) {
    .about-sm {
        width: 20rem !important;
    }

    .text1 {
        font-size: 1.2rem !important;
    }
}