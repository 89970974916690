* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.footer-container {
  background-color: #462828;
  color: white;
}

.styles-module_whatsappButton__IWx9V {
  background-color: #673b3b !important;
  animation: pop 1s infinite !important;
}

.styles-module_whatsappButton__IWx9V::after {
  content: "";
  display: none;
}

.fullnameOnForm,
textarea {
  color: white;
}

.info {
  padding: 20px;
  border: 2px solid white;
}

.g5 {
  width: 100%;
}

li {
  list-style: none;
  cursor: pointer;
}

.info-a {
  border-top: 1px white solid;
  border-bottom: 1px white solid;
  padding-bottom: 0px !important;
  padding: 0px !important;
  width: 100%;
}

.styles-module_notificationIndicator__gVmjV{
  display: none !important;
}

.styles-module_whatsappButton__tVits{
  background: var(--brown-background) !important;
}

.list1 {
  text-align: left;
  padding: 0px !important;
}

.list1 ul {
  padding-left: 0px;
  font-weight: lighter;
}

.info-a ul a {
  text-decoration: none;
  color: white;
}

.info-a ul a li {
  font-weight: lighter;
  font-size: small;
}

.btn10 {
  background: transparent !important;
  border: 1px solid white !important;
  border-radius: 5px !important;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: lighter !important;
  /* padding: 5px 20px !important; */
  width: 10rem;
  display: grid;
  place-content: center;
}
/* 
.btn10:hover {
  background-color: white !important;
  color: var(--brown-background) !important;
  transition: var(--button-transition) !important;
} */

.sec2 p,
.sec2 p span {
  font-size: small;
  font-weight: lighter;
  text-align: right;
}

input::placeholder,
textarea::placeholder {
  color: white;
  font-family: "Inter", sans-serif !important;
  font-weight: 10;
  font-size: small;
}

input,
textarea {
  /* background-color: ; */
  background: #673b3b;
  border: 0px solid white;
}

.contact-form__controls {
  background: #673b3b !important;
  color: #fff !important;
  border: 1px #673b3b white !important;
}

.contact-form__controls::placeholder {
  color: #fff !important;
}

.contact-form__controls:focus-within {
  border: 1px solid #fff !important;
}

.footer-container .motomart-logo {
  width: 15rem;
}

.footer-container .icons {
  font-size: 2rem;
  gap: 50px !important;
}

.bottom p {
  font-size: 15px !important;
  font-weight: lighter !important;
  margin-bottom: auto !important;
}

.contactText {
  margin-bottom: auto !important;
}

.addressText {
  margin-bottom: auto !important;
}

/* For Mobile phone screens */
@media (max-width: 480px) {
  * {
    margin: 0px !important;
    padding: 0px !important;
    box-sizing: border-box !important;
    max-width: 480px !important;
  }

  .info-a {
    width: 90%;
    margin-left: 25px !important;
  }

  .ll {
    width: 100%;
    display: flex;
    justify-content: center !important;
  }

  .l3 {
    display: flex;
    justify-content: center;
  }

  .icons {
    margin-top: 20px !important;
    width: 50% !important;
  }

  .info {
    width: 100% !important;
    padding: 0.6rem 0.6rem !important;
  }

  .sec2 p span {
    font-size: 13px;
    text-align: center !important;
  }

  .fullnameOnForm {
    margin-bottom: 15px !important;
  }

  .sec2 p,
  .sec2 p span {
    text-align: center !important;
  }

  .list1 {
    padding-left: 0px !important;
    text-align: center;
  }

  .form_g {
    width: 100%;
  }

  .form_g:first-child {
    margin-bottom: 10px;
  }

  .sec2 {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
  }

  .btn10 {
    /* font-size: 20px !important; */
    /* padding: 5px 10px !important; */
  }

  .addressText {
    margin-bottom: 15px !important;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .form_head {
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
  }

  .form_head .g3 {
    width: 100%;
  }

  .form_head .g5 {
    width: 90%;
  }

  ul {
    padding-left: 0px;
  }

  .info-a .list1 {
    text-align: left;
  }

  .l3 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer-container .ll {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-container .icons {
    width: 100%;
  }
}

@media (min-width: 1281px) and (max-width: 2559px) {
  .form_head {
    display: flex;
    padding-bottom: 10px;
  }
}

@media (min-width: 2560px) {
  .form_head {
    display: flex;
    padding-bottom: 10px;
  }

  .footer-container input,
  .footer-container textarea {
    padding: 20px 30px !important;
  }

  .f4,
  .f4 a li {
    font-size: 20px !important;
  }

  .g5 {
    width: 100%;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .footer-container {
    padding: 0px 50px !important;
  }

  .icons {
    width: 50% !important;
  }

  .l3 {
    display: flex;
    align-items: center;
  }

  .info {
    padding: 20px !important;
  }

  .form_g {
    width: 100% !important;
    margin-bottom: 20px !important;
  }

  .btn10 {
    /* padding: 4px 20px !important; */
    /* font-size: 20px !important; */
  }
}

@media (min-width: 769px) and (max-width: 1800px) {
  .form_g {
    margin-bottom: 1rem !important;
  }

  .info {
    width: 30rem !important;
  }

  .f4 {
    width: 33rem !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form_head .g5 {
    width: 100% !important;
  }
}
