* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;

}

.aboutMotomartContainer {
    padding-bottom: 10rem !important;
    padding: 0px 5rem;
}

.section1InAboutMotomart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 70px !important;
}

.section2InAboutMotomart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: -20% !important;
}

.circleOnDoor {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    z-index: 99;
    position: absolute;
    left: 16vw;
    bottom: 45vh;
    animation: pop 1.5s infinite;
}

.basinCircle {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    z-index: 99;
    position: absolute;
    left: 12.5vw;
    bottom: 55vh;
    animation: pop 1.5s infinite;
}

.circleOnChair {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    z-index: 99;
    position: absolute;
    left: 30vw;
    top: 45vh;
    animation: pop 1.5s infinite;
}

.chair {
    z-index: 20 !important;
    position: relative;
}

.basinTapCircle {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    z-index: 99;
    position: absolute;
    left: 33vw;
    bottom: 75vh;
    animation: pop 1.5s infinite;
}

.door {
    z-index: 1;
    height: 35rem;
    margin-bottom: 70px;
}

.basin,
.door {
    border-radius: 20px;
}

.basin {
    height: 45rem;
}

.aboutMotomartText {
    margin-bottom: 50px;
}

.bottomOnAboutMotomart {
    padding: 10px 80px;
    width: 80%;
    border: none;
    border-radius: 10px !important;
}

.doorWrapperInAboutMotomart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width:480px) {
    * {
        margin: 0px !important;
        padding: 0px !important;
        box-sizing: border-box !important;
        max-width: 480px !important;
    }

    .aboutMotomartContainer {
        padding-bottom: 10rem !important;
    }

    .aboutMotomartText {
        width: 20rem;
        margin-bottom: 1rem !important;
    }

    .aboutMotomartTextWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 2rem !important;
    }

    .aboutMotomartSubtext {
        font-size: 20px !important;
        margin-bottom: 10px !important;
    }

    .circleOnDoor,
    .circleOnChair,
    .bottomOnAboutMotomart,
    .basinCircle,
    .basinTapCircle {
        display: none;
    }

    .section2InAboutMotomart {
        margin-top: 0px !important;
    }

    .chair,
    .basin,
    .door {
        height: 25rem;
    }

    .text1 {
        font-size: 0.1rem !important;
        margin-bottom: 20px !important;
    }
}

@media (min-width:1350px) and (max-width:1800px) {
    .aboutMotomartText {
        width: 30rem !important;
    }

}

@media (min-width:1000px) and (max-width:1349px) {
    .aboutMotomartText {
        width: 20rem !important;
    }

    .aboutMotomartContainer {
        padding: 0px !important;
    }

    .aboutMotomartContainer {
        padding-bottom: 10rem !important;
        padding-left: 3rem !important;
        padding-right: 3rem !important;

    }
}

@media (min-width:481px) and (max-width:768px) {
    * {
        padding: 0px !important;
        margin: 0px !important;
        box-sizing: border-box !important;
    }

    .wrapper {
        padding-bottom: 30px !important;
    }

    .section2InAboutMotomart {
        margin: 0px !important;
    }

    .bottomOnAboutMotomart {
        display: none !important;
    }

    .aboutMotomartText {
        width: 40rem;
    }

    .aboutMotomartTextWrapper {
        padding: 0px 30px !important;
    }

    .circleOnDoor {
        background-color: white;
        width: .85rem;
        height: .85rem;
        border-radius: 50%;
        display: flex;
        z-index: 99;
        position: absolute;
        left: 34vw;
        bottom: 41vh;
        animation: pop 1.5s infinite;
    }

    .circleOnChair {
        background-color: white;
        width: .85rem;
        height: .85rem;
        border-radius: 50%;
        display: flex;
        z-index: 99;
        position: absolute;
        left: 70vw;
        top: 38vh;
        animation: pop 1.5s infinite;
    }


    .basinTapCircle {
        background-color: white;
        width: .85rem;
        height: .85rem;
        border-radius: 50%;
        display: flex;
        z-index: 99;
        position: absolute;
        left: 70vw;
        bottom: 75vh;
        animation: pop 1.5s infinite;
    }

    .basinCircle {
        background-color: white;
        width: .85rem;
        height: .85rem;
        border-radius: 50%;
        display: flex;
        z-index: 99;
        position: absolute;
        left: 24vw;
        bottom: 58vh;
        animation: pop 1.5s infinite;
    }
}

@media (min-width:769px) and (max-width:1439px) {
    .chair {
        height: 40rem !important;
    }

    .aboutMotomartContainer {
        width: 100% !important;
    }

    .section2InAboutMotomart {
        margin-right: -20px !important;
    }


    .aboutMotomartText {
        width: 35rem !important;
    }

    .basin {
        height: 40rem;
    }
}

@media (min-width:992px) and (max-width:1150px) {

    .chair{
        height: 30rem !important;
    }

    .basin{
        height: 30rem !important;
    }

    .door{
        height: 30rem !important;
    }

    .aboutMotomartContainer{
        padding: 0px 3rem !important;
        padding-bottom: 5rem !important;
    }

    .aboutMotomartText{
        width: 30rem !important;
    }
    .aboutMotomartSubtext{
        font-size: 1.5rem !important;
    }
}

@media (min-width:481px) and (max-width:680px){
    .aboutMotomartText{
        width: 25rem !important;
    }
}