* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.musthave_container {
    background: #221313;
    height: 100%;
    padding-left: 7rem;
    padding-top: 7rem;
}

.musthaveTextChair {
    display: flex;
    height: fit-content;
    z-index: 3;
}

.musthaveText {
    width: 35rem;
    margin-right: 50px;
    display: flex;
    padding-bottom: 35rem;
}

.chairInMusthave {
    height: 40rem;
    border-radius: 23px;
    position: relative;
    z-index: 99;
}

.musthave--wrapper {
    margin-bottom: 20rem;
    position: relative !important;
    animation: move 50s linear infinite;
}

@keyframes move {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(calc(-250px *6));
    }
}

.chair2InMusthave {
    padding-top: 15rem;
    border-radius: 23px !important;
    border-top-left-radius: 30px;
}

.lightImg2 {
    height: 23rem;
    margin-left: 5rem;
    margin-top: 15rem;
}

.lightWrapper {
    padding-top: 6rem;
    padding-left: 15rem !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.light2-wrapper {
    margin-right: 7rem;
}

.lightImg {
    width: 30rem;
}

.wallImg {
    position: absolute;
    right: -170%;
    top: -15%;
}

.textUnderLight {
    font-size: 30px;
    width: 55%;
}

.section2InMusthave {
    display: flex !important;
    flex-direction: row;
    position: absolute !important;
    bottom: -24%;
}

.doorImg {
    object-fit: contain;
    display: flex;
    position: absolute !important;
    top: -40%;
}

.doorWrapperInMusthave {
    display: flex;
    align-items: flex-start;
}

.textUnderLight2 {
    text-align: left;
    padding-left: 23rem;
    margin-top: 10px;
    font-size: 29px;
}

.textUnderTub {
    width: 50rem;
    margin-top: 3rem;
    font-size: 30px;
}

.musthaveButton {
    display: flex;
    justify-content: center;
    padding: 8rem 0px;
}

.circleOnChairInMusthave {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    right: 19vw;
    z-index: 99;
    bottom: 46vh;
    animation: pop 1.5s infinite;
}

.circleOnLight {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    right: 6vw;
    z-index: 99;
    top: 38vh;
    animation: pop 1.5s infinite;
}

.circleOnTub {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    right: 9vw;
    z-index: 99;
    top: 31vh;
    animation: pop 1.5s infinite;
}

.circleOnDoorInMusthave {
    background-color: white;
    width: .85rem;
    height: .85rem;
    border-radius: 50%;
    display: flex;
    position: absolute;
    left: 27vw;
    z-index: 99;
    top: 1vh;
    animation: pop 1.5s infinite;
}

@media (max-width:770px) {
    .musthaveText {
        width: 20rem !important;
        margin-right: 3rem !important;
    }

    .chairInMusthave {
        height: 25rem !important;
    }

    .text_chair_container {
        display: flex !important;
        align-items: center !important;
    }

    .lightImg {
        width: 20rem !important;
        margin: 0px !important;
    }

    .lightWrapper {
        top: 20rem;
        padding-left: 8rem !important;
    }

    .tubImg {
        width: 20rem !important;
    }

    .musthave--wrapper {
        margin-bottom: 20rem !important;
        padding-left: 2.5rem !important;
    }

    .textUnderTub {
        font-size: 18px !important;
        width: 28rem !important;
    }

    .doorImg {
        width: 20rem !important;
        top: -11rem !important;
    }

    .wallImg {
        width: 20rem !important;
        right: -200%;
        top: 30%;
    }

    .chair2InMusthave {
        height: 40rem !important;
        padding-top: 15rem !important;
    }

    .lightImg2 {
        height: 15rem !important;
        width: 20rem !important;
        bottom: -45%;
    }

    .textUnderLight {
        font-size: 18px !important;
        width: 70%;
        margin: 0px !important;
    }

    .textUnderLight2 {
        font-size: 20px !important;
        margin-top: 1rem !important;
    }

    .light2-wrapper {
        margin: 0px 5rem !important;
    }

    .textUnderLight2 {
        position: relative;
        font-size: 1.1rem !important;
        bottom: -45% !important;
        padding-left: 10rem !important;
    }

    .btnWrapper {
        padding-bottom: 5rem !important;
    }

    .musthaveBtn {
        font-size: 20px !important;
        padding: 10px 30px !important
    }

    .section2InMusthave {
        bottom: 0px !important;
    }
}


@media (max-width:500px) {
    .lightWrapper {
        margin-left: 12rem !important;
    }

    .lightImg {
        width: 19rem !important;
    }

    .wallImg {
        width: 17rem !important;
        right: -180% !important;
        top: 60% !important;
    }
    .doorImg{
        top: -6rem !important;
    }
}